<template>
  <div class="accessRecord">
    <div class="table-container">
      <el-table
        class="setheight"
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        @sort-change='sort_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column
					prop="deviceName"
          align="center"
					label="设备名称"
					min-width="220">
				</el-table-column>
				<el-table-column
					prop="deviceType"
          align="center"
					label="设备型号"
					min-width="220">
				</el-table-column>
				<el-table-column
					prop="deviceAddress"
          align="center"
					label="安装位置"
					min-width="220">
				</el-table-column>
        <el-table-column
          prop="onlineStatus"
          align="center"
          min-width="220"
          label="在线状态"
        >
          <template slot-scope="scope">
            <div :class="scope.row.onlineStatus ===1 ? 'onLine': 'offLine'">{{ scope.row.onlineStatus===1 ? '在线' : '离线' }}</div>
          </template>
        </el-table-column>
				<!-- <el-table-column
					prop="accessMode"
          align="center"
					label="进出方式"
					min-width="200">
				</el-table-column>
				<el-table-column
					prop="passageTime"
          align="center"
					label="通行时间"
					min-width="245">
				</el-table-column> -->
        <!-- <el-table-column
          align="center"
          min-width="200"
					prop="createTime"
          sortable='custom'
					label="创建时间">
				</el-table-column> -->
        <!-- <el-table-column
          align="center"
          width="400px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button" v-if="buttonAuth.includes('system:config:edit')" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" v-if="buttonAuth.includes('system:config:remove')" @click="openDeleteDialog([scope.row.configId])">{{$t('delete')}}</el-button>
          </template>
				</el-table-column> -->
			</el-table>
    </div>
    <div class="page-wrapper">
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <!-- <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog> -->
    <!-- 删除确认弹窗 -->
    <!-- <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import UserSearchClass from './userSearchClass'
import apis from '@/apis'
import mixin from '@/mixins/index'
import pageClass from "@/unit/pageClass";
// import addDialog from './dialog/addDialog.vue'
// import confirmDialog from '@/components/confirmDialog.vue'
class UserSearchClass extends pageClass {
  constructor(type) {
    super();
    if (type === "form") {
      this.categoryId = "";
      this.searchValue = "";
    }
  }
}

export default {
  components: {
    // addDialog,
    // confirmDialog,
  },
  mixins: [mixin],
  data() {
    return {
      // form: new IndexClass("form"), // 实例化一个表单的变量
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      // tableData: [{}],//TODO：初始化数据
      venueBox: [], // 场馆下拉框
       tableData: [ //TODO:模拟数据
        {
          deviceId: '001',
          deviceName: '闸机1号',
          deviceType: 'wh-001',
          venue: '羽毛球馆',
          deviceAddress: '东门出口',
          IntelligentSwitchGateway: 10,
          temperature: '37',
          collectCount: 5000,
          onlineStatus: 1,
          addTime: '2020-11-20',
          doorStatus: 1,
        },
        {
          deviceId: '002',
          deviceName: '闸机2号',
          deviceType: 'wh-002',
          venue: '羽毛球馆',
          deviceAddress: '南门出口',
          IntelligentSwitchGateway: 2,
          temperature: '37',
          collectCount: 10000,
          onlineStatus: 2,
          addTime: '2020-11-20',
          doorStatus: 1,
        },
        {
          deviceId: '004',
          deviceName: '闸机4号',
          deviceType: 'wh-004',
          venue: '羽毛球馆',
          deviceAddress: '北门出口',
          IntelligentSwitchGateway: 5,
          temperature: '37',
          collectCount: 5000,
          onlineStatus: 1,
          addTime: '2020-11-20',
          doorStatus: 2,
        },
        {
          deviceId: '005',
          deviceName: '闸机5号',
          deviceType: 'wh-005',
          venue: '羽毛球馆',
          deviceAddress: '东南门出口',
          IntelligentSwitchGateway: 9,
          temperature: '37',
          collectCount: 5000,
          onlineStatus: 1,
          addTime: '2020-11-20',
          doorStatus: 2,
        },
        {
          deviceId: '006',
          deviceName: '闸机6号',
          deviceType: 'wh-006',
          venue: '羽毛球馆',
          deviceAddress: '东门出口',
          IntelligentSwitchGateway: 10,
          temperature: '37',
          collectCount: 5000,
          onlineStatus: 2,
          addTime: '2020-11-20',
          doorStatus: 1,
        },
      ],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      statisticsData: [
        {
          total: 30,
          text: '场馆当前人数（人）',
          // imgBg: require('../../../../assets/images/venue_curr_num.png'),
        },
        {
          total: 66,
          text: '当日进入人数（人）',
          // imgBg: require('../../../../assets/images/enter_curr_day_num.png'),
        },
        {
          total: 11,
          text: '当日出馆人数（人）',
          // imgBg: require('../../../../assets/images/out_curr_day_num.png'),
        },
        {
          total: 30,
          text: '异常体温人数（人）',
          // imgBg: require('../../../../assets/images/abnormal_temp.png'),
        },
      ],
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        'Y': '是',
        'N': '否',
      }
      return typeMap[type]
    },
  },
   activated() {
    //  TODO:场馆下拉，需要请求接口的数据
    this.$http.get(apis.getMainDirectionList).then((res) => {
      if (res.data.code === 0) {
        this.venueBox = res.data.rows
      }
    })
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.config_List, {
        params: this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          // this.tableData = res.data.rows //TODO 需要接口初始化表格
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.config_remove, {}, { params: this.confirmDialog.data }).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = id.join(',')
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.confirmDialog.data = data
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === '1' ? '0' : '1',
        },
      }
      this.$http.post(apis.userStatus, form)
    },
  },
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
.statistics{
  .wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .single{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 266px;
      height: 113px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(53, 46, 46, 0.1);
      border-radius: 10px;
      position: relative;
      .imgbg{
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 10px;
        left: 50%;
        // transform: translateX(-50);
        margin-left: -50px;
      }
      .totalText{
        font-size: 30px;
        font-size: 30px;
        font-weight: bold;
        color: #439BFF;
        line-height: 40px;
      }
      .text{
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #4F4F4F;
        line-height: 19px;
      }
    }
  }
}
// .setheight{
//     height: 300px !important;
// }
.page-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.onLine{
  color: #6DD400;
}
.offLine{
  color: #E02020;
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
</style>
